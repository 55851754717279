import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import HeroModule from 'DesignComponents/Organisms/Hero/HeroModule';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import MagazineOverviewPageModel from 'Models/Pages/MagazineOverviewPage/MagazineOverviewPageModel.interface';

function MagazineOverviewPage() {
  const { hero, contentArea, inEditMode } =
    useCurrentPage<MagazineOverviewPageModel>();

  return (
    <>
      {hero && <HeroModule hero={hero} />}

      <ContentContainer {...applyEditModeAttr(inEditMode && 'MainContent')}>
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </ContentContainer>
    </>
  );
}

export default MagazineOverviewPage;
